import React from 'react';
import ImageGallery from 'react-image-gallery';
import "./image-gallery.css";
import './Home.css';


const images = [
  {
    original: 'https://itjustgetsbettermedia.b-cdn.net/141st%20Lane%20Music%20Logo.PNG',
  },
  {
    original: 'https://itjustgetsbettermedia.b-cdn.net/Cxmmunity%20Logo%20(Black).png',
  },
  {
    original: 'https://itjustgetsbettermedia.b-cdn.net/SuperAudioPros%20Logo.PNG'
  },
  {
    original: 'https://itjustgetsbettermedia.b-cdn.net/904%20Happy%20Hour%20Logo.JPG'
  }
]

class Home extends React.Component { 

  constructor() {
    super();
    this.state = { 
      mobile: false,
      isOpen: false,
      page: 'Home'
    };
    
  }

  componentDidMount()
  {
    

   
    
  }
  
  render()
  {  
    return ( 
      <div className='pageContainer'>
        <center><img src='https://itjustgetsbettermedia.b-cdn.net/logo_itjustgetsbetter.png' className='logo'></img></center>
        <ImageGallery items={images} showFullscreenButton={false} showPlayButton={false} showThumbnails={false} lazyLoad={true} showBullets={true} showIndex={false} autoPlay={true}/>
        <br></br>
        <center>
          <h1 className='galleryDesc'>people who we work with</h1>
          <br></br>
          <h1 className='roboto'>SERVICES</h1>
          <div className='aptContainer'>
            <div className='aptCard'>
              <center>
                <span className='head'>ADVICE</span>
                <br></br>
                <span className='body'>talking through problems and brainstorming solutions/strategies</span>
              </center>
            </div>
            <div className='aptCard'>
              <center>
                <span className='head'>BUSINESS PLANS</span>
                <br></br>
                <span className='body'>determining obtainable goals and laying out a road map for success</span>
              </center>
            </div>
            <div className='aptCard'>
              <center>
                <span className='head'>WRITING</span>
                <br></br>
                <span className='body'>articulate communication that increases consumer or donor buy-in </span>
              </center>
            </div>
            <div className='aptCard'>
              <center>
                <span className='head'>MARKETING</span>
                <br></br>
                <span className='body'>leveraging digital and physical assets to increase awareness</span>
              </center>
            </div>
            <div className='aptCard'>
              <center>
                <span className='head'>RESEARCH</span>
                <br></br>
                <span className='body'>understanding the world around us through non-bias investigation</span>
              </center>
            </div>
            <div className='aptCard'>
              <center>
                <span className='head'>BUSINESS PROPOSALS</span>
                <br></br>
                <span className='body'>pitching mutually beneficial ideas with creative, concise presentations</span>
              </center>
            </div>
          </div>

          <br></br>
          
        
          <div className='aptContainer'>
            <div className='aptCard'><img src='https://itjustgetsbettermedia.b-cdn.net/Wilshem_Pennick_Headshot.png' style={{width: '100%'}}></img></div>
            <div className='aptCard'>
              <div className='verticalCenter'>
            <span className='name'>Wilshem Pennick</span>
            <br></br>
            <span className='role'>Founder, President</span>
            <br></br>
            
            <br></br>
            <span className='desc'>Gen Z entrepreneur/researcher with a passion for finding and sharing innovative solutions. </span>
            <br></br>
            <br></br>

            </div>
            </div>
            <div></div>
          </div>
          <span className='name'>CONTACT ME</span>
          <br></br>
          
          <a style={{marginTop: '1em', color: 'black'}} href='tel:+1904-515-2089'><h2 style={{marginTop: '1em', color: 'black'}}>904-515-2089</h2></a>
          <a style={{marginTop: '1em', color: 'black'}} href='mailto:wilshem@itjustgetsbetter.org'><h2 style={{marginTop: 'em', color: 'black'}}>wilshem@itjustgetsbetter.org</h2></a>

          <br></br>
          <br></br>
          <h1 className='roboto'>NOTABLE ACCOMPLISHMENTS</h1>
          <div className='aptContainer'>
            <div className='aptCard'>
              <center>
                <span className='head'>PUBLIC RELATIONS</span>
                <br></br>
                <span className='body'>sent a 100+ postcards from Jacksonville residents to the families of those affected by a hate crime</span>
              </center>
            </div>
            <div className='aptCard'>
              <center>
                <span className='head'>CREATIVE</span>
                <br></br>
                <span className='body'>designed custom and ready-to-wear clothing for entrepreneurs, executives, and thought leaders</span>
              </center>
            </div>
            <div className='aptCard'>
              <center>
                <span className='head'>FUNDRAISING</span>
                <br></br>
                <span className='body'>raised six-figures of funds for free healthcare programs during a pandemic</span>
              </center>
            </div>
            <div className='aptCard'>
              <center>
                <span className='head'>STRATEGY</span>
                <br></br>
                <span className='body'>set up the pricing matrix for a transportation company based in Atlanta, Georgia</span>
              </center>
            </div>
            <div className='aptCard'>
              <center>
                <span className='head'>START UP</span>
                <br></br>
                <span className='body'>founded a streetwear brand while in college and recruited a team of 60 influencers/marketers</span>
              </center>
            </div>
            <div className='aptCard'>
              <center>
                <span className='head'>VOLUNTEERING</span>
                <br></br>
                <span className='body'>serve as the Vice Chairman of the Board for a non-profit that provides clothing and hygiene items to homeless or underprivileged youth</span>
              </center>
            </div>
          </div>
          </center>     
      </div>
    )
    
    
          
      
  }
}
export default Home;
